.slider-container {
  position: relative;
  margin: 0 auto;
}

/* Line indicator styles */
.slick-dots {
  bottom: -25px;
  display: flex !important;
  justify-content: center;
  gap: 8px;
  padding: 0;
}

.slick-dots li {
  width: 24px;
  height: 2px;
  margin: 0;
}

.slick-dots li button {
  width: 24px;
  height: 2px;
  padding: 0;
}

.slick-dots li button:before {
  content: '';
  width: 24px;
  height: 2px;
  background: #4B4B4B;
  opacity: 1;
  top: 0;
  left: 0;
}

.slick-dots li.slick-active button:before {
  background: #8B5CF6;
  opacity: 1;
}

/* Slide transition */
.slick-slide {
  transition: opacity 0.3s ease;
}

/* Image styles */
.slider-container img {
  max-height: 500px;
  object-fit: contain;
  margin: 0 auto;
} 